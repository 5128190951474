// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { Box, Typography } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { useRouteMatch } from "@/hooks/useRouteMatch";
import { HttpStatus } from "@/interfaces/global";
import { IReferential, ISpeciesDetails } from "@/interfaces/referential";
import { UserType } from "@/interfaces/user";
import { IVaccine, VaccineType } from "@/interfaces/vaccination";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import ReferentialService from "@/services/ReferentialService";
import VaccinationService from "@/services/VaccinationService";
import { FormInterventionProvider } from "./useFormIntervention";

export default function VaccinationSite() {
    const auth = useAuth();

    const routeMatch = useRouteMatch([
        routerLinks.iahp.vaccinationSite.view(),
        routerLinks.iahp.vaccinationSite.form(),
        routerLinks.iahp.vaccinationSite.editForm(),
        routerLinks.iahp.vaccinationSite.vaccinationIntervention.base(),
        routerLinks.iahp.vaccinationSite.vaccinationIntervention.form(),
        routerLinks.iahp.vaccinationSite.vaccinationIntervention.editForm(),
    ]);
    const currentTab = routeMatch?.pattern?.path;

    const [vaccines, setVaccines] = useState<IVaccine[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);
    const [horsePower, setHorsePower] = useState<IReferential[]>([]);
    const [sectors, setSectors] = useState<IReferential[]>([]);
    const [tiers, setTiers] = useState<IReferential[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getVaccines(), getSpecies(), getHorsePower(), getSectors(), getTiers()]).then(() => {
            setLoading(false);
        });
    };

    const getVaccines = async () => {
        setLoading(true);
        const res = await VaccinationService.getVaccines();
        if (res.status === HttpStatus.OK) {
            setVaccines(res.data);
        }
        setLoading(false);
    };

    const getHorsePower = async () => {
        const res = await VaccinationService.getHorsePower();
        if (res.status === HttpStatus.OK) {
            setHorsePower(res.data);
        }
    };

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["iahp", "drug"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(res.data);
        }
    };

    const getSectors = async () => {
        const res = await VaccinationService.getSectors();
        if (res.status === HttpStatus.OK) {
            setSectors(res.data);
        }
    };

    const getTiers = async () => {
        const res = await VaccinationService.getTiers();
        if (res.status === HttpStatus.OK) {
            setTiers(res.data);
        }
    };

    //Permet de gérer les états de modification des interventions de vaccination et de chantier
    //on doit rester sur les tabs mais avoir des URLs différentes en cas de modif
    const handleFirstTab = () => {
        switch (currentTab) {
            case routerLinks.iahp.vaccinationSite.view():
                return routerLinks.iahp.vaccinationSite.view();
            case routerLinks.iahp.vaccinationSite.vaccinationIntervention.form():
                return routerLinks.iahp.vaccinationSite.vaccinationIntervention.form();
            case routerLinks.iahp.vaccinationSite.vaccinationIntervention.editForm():
                return routerLinks.iahp.vaccinationSite.vaccinationIntervention.editForm();
        }
    };

    return (
        <>
            <PermissionsTabs
                tabControl={currentTab}
                tabs={[
                    {
                        value: handleFirstTab(),
                        url: routerLinks.iahp.vaccinationSite.view(),
                        label: "Mes chantiers",
                    },
                    {
                        url: routerLinks.iahp.vaccinationSite.form(),
                        value:
                            currentTab === routerLinks.iahp.vaccinationSite.editForm()
                                ? routerLinks.iahp.vaccinationSite.editForm()
                                : routerLinks.iahp.vaccinationSite.form(),
                        label: "Saisir un chantier",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                        preferences: [Preference.IAHP],
                    },
                    {
                        url: routerLinks.iahp.vaccinationSite.vaccinationIntervention.base(),
                        label: "Mes interventions de vaccination",
                    },
                ]}
            />
            <FormInterventionProvider>
                <PageContent>
                    {auth.userInfo.type !== UserType.VETERINARY ||
                    auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) ? (
                        <Outlet
                            context={{
                                vaccines: vaccines.filter((v) => v.type !== VaccineType.DILUENT && v.useKey === "iahp"),
                                diluents: vaccines.filter((v) => v.type === VaccineType.DILUENT && v.useKey === "iahp"),
                                loading,
                                species: species.filter((s) => !s.parentUuid),
                                subSpecies: species.filter((s) => s.parentUuid),
                                horsePower,
                                sectors,
                                tiers,
                            }}
                        />
                    ) : (
                        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                            <Typography variant="h4">
                                Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                                vétérinaire sanitaire.
                            </Typography>
                        </Box>
                    )}
                </PageContent>
            </FormInterventionProvider>
        </>
    );
}
